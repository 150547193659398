import React from "react";
import { Col, Row } from "react-bootstrap";

import { 
  DiDocker,
  DiNodejs,
  DiReact } from 'react-icons/di';
import {
  SiVisualstudiocode,
  SiPostman,
  SiOpenssl,
  SiKubernetes,
  SiVercel,
  SiWireshark,
  SiGithub,
  SiSwagger
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWireshark />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiOpenssl />
      </Col>
      <Col xs={4} md={2} className="tech-icons" hidden>
        <SiVercel />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGithub />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSwagger />
      </Col>
    </Row>
  );
}

export default Toolstack;
